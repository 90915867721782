<template>
  <b-overlay
    :show="appointmentPending || customerLoggedStore.isPending || cardsListStore.isPending || isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div id="user-profile">
      <stylist-page-header :header-data="stylistData.header" />
      <b-container
        class="pb-2 py-md-4 px-sm-2 px-lg-4"
      >
        <b-row class="mt-0 mb-0">
          <!-- content -->
          <b-col
            lg="9"
            cols="12"
          >
            <tbb-card
              v-if="appointment"
              title="Confirm your appointment"
            >
              <div class="mt-2">
                <validation-observer
                  ref="refFormCustomerObserver"
                >
                  <b-form
                    id="reserve-appointment-form"
                    slot-scope="{ validate }"
                    class="p-2"
                    @submit.prevent="confirmationAppointment(validate())"
                  >
                    <b-form-row
                      class="mt-2"
                    >
                      <b-col
                        cols="12"
                        lg="4"
                        xl
                      >
                        <!-- Field: Name -->
                        <validation-provider
                          name="Name"
                          rules="required"
                        >
                          <b-form-group
                            slot-scope="{ valid, errors }"
                            label="Name"
                            label-for="name"
                          >
                            <b-form-input
                              id="name"
                              v-model="appointment.customer.name"
                              :state="errors[0] ? false : null"
                              size="lg"
                            />
                            <b-form-invalid-feedback>
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="4"
                        xl
                      >
                        <b-form-group
                          label="Last name"
                          label-for="last-name"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="appointment.customer.lastName"
                            size="lg"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        lg="4"
                        xl
                      >
                        <!-- Cell phone number -->
                        <validation-provider
                          name="Cell phone number"
                          rules="regex:^(\+?[0-9]+)$"
                        >
                          <b-form-group
                            slot-scope="{ valid, errors }"
                            label="Cell phone number"
                            label-for="phone"
                          >
                            <b-form-input
                              id="phone"
                              v-model="appointment.customer.phone"
                              name="phone"
                              :state="errors[0] ? false : null"
                              size="lg"
                            />
                            <b-form-invalid-feedback>
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-form-row>

                    <b-form-row
                      v-if="!selectedCard.id"
                      class="mt-2"
                    >
                      <b-col
                        v-if="stripeAccountEnabled && (appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY])"
                        cols="12"
                        lg="6"
                        xl="6"
                      >
                        <b-form-group
                          label="Credit Card number"
                          label-for="card-number"
                        >
                          <div
                            v-if="appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY]"
                            id="card-number"
                            class="form-control form-control-lg"
                            :class="{'is-invalid': cardError.number.empty || cardError.number.message}"
                          >
                          <!--Stripe.js injects the Card Element-->
                          </div>
                          <div class="invalid-feedback">
                            {{ cardError.number.message ? cardError.number.message : "Card number cannot be empty" }}
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="stripeAccountEnabled && (appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY])"
                        cols="6"
                        lg="3"
                        xl="3"
                      >
                        <b-form-group
                          label="Card expiration date"
                          label-for="card-expiry"
                        >
                          <div
                            v-if="appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY]"
                            id="card-expiry"
                            class="form-control form-control-lg"
                            :class="{'is-invalid': cardError.expiry.message}"
                          >
                          <!--Stripe.js injects the Card Element-->
                          </div>
                          <div class="invalid-feedback">
                            {{ cardError.expiry.message }}
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="stripeAccountEnabled && (appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY])"
                        cols="6"
                        lg="3"
                        xl="3"
                      >
                        <b-form-group
                          label="CVC code"
                          label-for="card-cvc"
                        >
                          <div
                            v-if="appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY]"
                            id="card-cvc"
                            class="form-control form-control-lg"
                            :class="{'is-invalid': cardError.cvc.message}"
                          >
                          <!--Stripe.js injects the Card Element-->
                          </div>
                          <div
                            v-if="cardError.cvc.message"
                            id="credit-card-error"
                            class="invalid-feedback"
                          >
                            {{ cardError.cvc.message }}
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row
                      v-if="selectedCard.id && (appointment.stylistPolicies[CANCELLATION_POLICY] || appointment.stylistPolicies[NO_SHOW_POLICY])"
                      class="mt-2"
                    >
                      <b-col cols="12">
                        <b-alert
                          variant="primary"
                          show
                        >
                          <p class="px-2">
                            You have provided information for credit card ending with {{ selectedCard.last4 }} during your recent booking!
                            <span
                              class="cursor-pointer"
                              @click="clearSelectedCard()"
                            >
                              click here to change your CC information
                            </span>
                          </p>
                        </b-alert>
                      </b-col>
                    </b-form-row>
                    <b-form-row
                      class="mt-2"
                    >
                      <b-col cols="12">
                        <!-- About your appointment -->
                        <validation-provider
                          name="About your appointment"
                        >
                          <b-form-group
                            slot-scope="{ valid, errors }"
                            label="About your appointment"
                            label-for="notes"
                          >
                            <b-form-textarea
                              id="notes"
                              v-model="appointment.note"
                              name="notes"
                              :state="errors[0] ? false : null"
                              size="lg"
                              rows="4"
                            />
                            <b-form-invalid-feedback>
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="(stylistPolicies[CANCELLATION_POLICY] || stylistPolicies[NO_SHOW_POLICY]) && stripeAccountEnabled"
                        cols="12"
                      >
                        <b-alert
                          variant="danger"
                          show
                        >
                          <p class="px-2">
                            Your card will be charged unless the service requested requires a deposit, service is completed, you don't show for your appointment or cancel within the specified timeframe.
                          </p>
                        </b-alert>
                      </b-col>
                      <booking-service-cancellation-policy
                        v-if="JSON.parse(appointment.stylistPolicies)[CANCELLATION_POLICY] && stripeAccountEnabled"
                        :charge-fee="JSON.parse(appointment.stylistPolicies)[CANCELLATION_POLICY].value"
                        :cancellation-time="JSON.parse(appointment.stylistPolicies)[HOURS_BEFORE_APPOINTMENT_POLICY].value"
                      />
                      <booking-service-no-show-policy
                        v-if="JSON.parse(appointment.stylistPolicies)[NO_SHOW_POLICY] && stripeAccountEnabled"
                        :charge-fee="JSON.parse(appointment.stylistPolicies)[NO_SHOW_POLICY].value"
                      />
                      <b-form-checkbox-group
                        v-if="(JSON.parse(appointment.stylistPolicies)[CANCELLATION_POLICY] || JSON.parse(appointment).stylistPolicies[NO_SHOW_POLICY]) && stripeAccountEnabled"
                        v-model="policyAgreementValue"
                        :options="policyAgreementOptions"
                        name="policy-agreement"
                        required
                      />

                    </b-form-row>
                    <b-form-row>
                      <b-col
                        cols="12"
                        class="text-right"
                      >
                        <b-button
                          class="m-1"
                          variant="primary"
                          type="submit"
                        >
                          Accept
                        </b-button>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </validation-observer>
              </div>
            </tbb-card>
          </b-col>
          <!-- content -->

          <!-- right side -->
          <b-col
            lg="3"
            cols="12"
            order="3"
          >
            <stylist-page-open-hours :open-hours-data="openHours" />
            <stylist-page-social-media
              :social-media-data="stylistData.socialMedia"
            />
          </b-col>
          <!-- right side -->

        </b-row>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import { BOOKING_SUCCESS_PAGE, ERROR_404 } from '@/router/routes/routes-names'
import {
  BAlert,
  BOverlay, BRow, BCol, BFormRow,
  BFormCheckboxGroup, BContainer,
  BForm, BFormGroup,
  BFormInput, BFormTextarea, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js/pure'

import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import StylistPageOpenHours from '@/components/stylist/stylist-page/StylistPageOpenHours.vue'
import StylistPageSocialMedia from '@/components/stylist/stylist-page/StylistPageSocialMedia.vue'
import BookingServiceCancellationPolicy from '@/components/booking/booking-service/BookingServiceCancellationPolicy.vue'
import BookingServiceNoShowPolicy from '@/components/booking/booking-service/BookingServiceNoShowPolicy.vue'
import {
  CANCELLATION_POLICY,
  HOURS_BEFORE_APPOINTMENT_POLICY,
  NO_SHOW_POLICY,
} from '@/dictionaries/stylistPolicyConfigDictionary'
import TbbCard from '../../sites/TbbCard.vue'

/* eslint-disable global-require */
export default {
  name: 'Booking',
  components: {
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BOverlay,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BContainer,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    StylistPageHeader,
    StylistPageOpenHours,
    StylistPageSocialMedia,
    BookingServiceCancellationPolicy,
    BookingServiceNoShowPolicy,
    TbbCard,
  },
  data() {
    return {
      appointmentId: null,
      appointment: null,
      appointmentPending: true,
      policyAgreementValue: [],
      policyAgreementOptions: [
        { text: 'I Agree with all above', value: true },
      ],
      stripe: null,
      stripeId: null,
      stripeAccountEnabled: false,
      selectedCard: {
        id: null,
      },
      creditCardError: false,
      creditCardComplete: false,
      creditCardEmpty: false,
      card: {},
      CANCELLATION_POLICY,
      HOURS_BEFORE_APPOINTMENT_POLICY,
      NO_SHOW_POLICY,
      cardError: {
        number: {
          complete: false,
          message: null,
          empty: false,
        },
        expiry: {
          complete: false,
          message: null,
        },
        cvc: {
          complete: false,
          message: null,
        },
      },
      isRenderStripeElement: false,
      stylistPolicies: null,
      isPending: false,
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistData: state => {
        const response = state?.stylist?.response

        return {
          header: {
            avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
            businessName: response?.businessName,
            coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
          },

          description: response?.businessDescription,

          // Contact
          contact: {
            businessName: response?.businessName,
            email: response?.email,
            address: response?.address,
            city: response?.city,
            postCode: response?.postCode,
          },

          // Social media
          socialMedia: {
            facebookUrl: response?.facebookUrl,
            twitterUrl: response?.twitterUrl,
            instagramUrl: response?.instagramUrl,
          },

          response,
          isPending: state?.stylist?.isPending,
          error: state?.stylist?.error,
        }
      },
      openHours: state => state.openHours.response || [],
      stylistStore: state => state.stylist,
    }),
    ...mapState('BookingServiceStoreModule', {
      customerLoggedStore: state => state.customerLogged,
      cardsListStore: state => state.cardsList,
    }),
  },
  watch: {
    stylistStore: {
      deep: true,
      immediate: true,
      async handler(newStylistData) {
        if (newStylistData.response && newStylistData.response.stripeId) {
          const { stripeId } = newStylistData.response

          this.stripeId = stripeId
          this.stripeAccountEnabled = newStylistData.response.stripeAccountEnabled

          if (!this.appointmentPending) {
            await this.renderStripeElementCard()
          }
        }
      },
    },
    cardsListStore: {
      deep: true,
      handler(newCardsList) {
        if (newCardsList.response) {
          this.selectedCard = { ...newCardsList.response[0] }
        }
      },
    },
    selectedCard: {
      deep: true,
      handler(newSelectedCard) {
        if (!newSelectedCard && this.stripeId) {
          this.renderStripeElementCard()
        }
      },
    },
    async appointmentId(id) {
      try {
        this.appointmentPending = true

        this.appointment = await this.fetchAppointment(id)
        this.appointmentPending = false
      } catch (e) {
        this.$router.push({ name: ERROR_404 })
      }

      this.$nextTick(() => {
        this.fetchCustomerCardsList()
        this.renderStripeElementCard()
      })
    },
  },
  mounted() {
    const { id } = this.$route.params

    this.appointmentId = id

    if (!this.stylistData.header.businessName) {
      this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
    }

    if (this.openHours.length === 0) {
      this.fetchPublicOpenHours()
    }

    this.fetchStylistPolicies()
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'fetchAppointmentByUuid',
      'fetchCustomerCardsList',
      'fetchStylistPolicies',
      'confirmAppointment',
      'createStripeCustomer',
      'addCardToCustomer',
    ]),
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
      'fetchPublicOpenHours',
    ]),
    async fetchAppointment(id) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(id)) {
        return this.fetchAppointmentByUuid(id)
      }

      throw new Error('unsupported')
    },
    clearSelectedCard() {
      this.selectedCard = {
        id: null,
      }
      this.renderStripeElementCard()
    },

    async renderStripeElementCard() {
      if (!this.selectedCard.id && (this.appointment.stylistPolicies[CANCELLATION_POLICY] || this.appointment.stylistPolicies[NO_SHOW_POLICY])) {
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: this.stripeId })
        this.elements = this.stripe.elements()
        this.card.number = this.elements.create('cardNumber')
        this.card.number.mount('#card-number')
        this.card.number.on('change', event => {
          this.cardError.number.empty = event.empty
          this.cardError.number.message = event.error ? event.error.message : null
          this.cardError.number.complete = event.complete
        })

        this.card.expiry = this.elements.create('cardExpiry')
        this.card.expiry.mount('#card-expiry')
        this.card.expiry.on('change', event => {
          this.cardError.expiry.message = event.error ? event.error.message : null
          this.cardError.expiry.complete = event.complete
        })

        this.card.cvc = this.elements.create('cardCvc')
        this.card.cvc.mount('#card-cvc')
        this.card.cvc.on('change', event => {
          this.cardError.cvc.message = event.error ? event.error.message : null
          this.cardError.cvc.complete = event.complete
        })
      }
    },
    async confirmationAppointment(validate) {
      const success = await validate
      this.isPending = true
      if (!success) {
        this.cardError.number.empty = true
        this.isPending = false
        return
      }

      let stripeCardId = null
      const appointmentId = this.appointment.id

      if (this.stripeAccountEnabled && !this.selectedCard.id && this.appointment.stylistPolicies[CANCELLATION_POLICY] && this.appointment.stylistPolicies[NO_SHOW_POLICY]) {
        if (!this.customerLoggedStore.response || !this.customerLoggedStore.response.stripeCustomerId || !this.checkIfCardDataIsComplete()) {
          await this.createStripeCustomer({
            name: this.appointment.customer.name,
            email: this.appointment.customer.email,
            lastName: this.appointment.customer.lastName,
            phone: this.appointment.customer.phone,
          })
        }

        stripeCardId = await this.saveNewCardInAppointment()
      }

      await this.confirmAppointment({
        appointmentId,
        stripeCardId,
        customer: this.appointment.customer,
        note: this.appointment.note,
      }).then(() => {
        this.$router.push({ name: BOOKING_SUCCESS_PAGE })
      })
      this.isPending = false
    },
    checkIfCardDataIsComplete() {
      return this.cardError.number.complete && this.cardError.expiry.complete && this.cardError.cvc.complete
    },
    async saveNewCardInAppointment() {
      const result = await this.stripe.createToken(this.card.number)

      if (result.error) {
        throw new Error('unable to create stripe token')
      }

      const card = await this.addCardToCustomer(result.token.id)

      return card.data.id
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
