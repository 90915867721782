<template>
  <div>
    <h5>No-show Policy</h5>
    <p>Please note that if you are <b>over 15 minutes late</b> for your appointment we may have to reschedule your service if it cannot be completed in the remaining time frame. All attempts to reschedule in a timely manner will be made. If we do not hear from you 15 or more minutes into your scheduled appointment time it is considered a “No-Show” and your card will be <b>charged for {{ chargeFee }}%</b> of the service scheduled.</p>
  </div>
</template>

<script>

export default {
  props: {
    chargeFee: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: [],
      options: [
        { text: 'I Agree', value: true },

      ],
    }
  },
}
</script>

<style scoped>
div{margin-top: 0.5rem;}
p{font-size: 12px;}
</style>
